<!-- 问答管理页面 -->
<template>
  <div
    v-loading="loading"
    element-loading-text="数据较多，拼命加载中..."
    class="qumanage-container"
  >
    <div class="hd">
      <div class="name">
        <span>问答管理</span>
        <!-- 新增问答按钮行 -->
        <div v-show="activeName == '1'" class="newadd">
          <el-button type="primary" size="mini" @click="addDrawer('add')"
            >新增问答</el-button
          >
          <el-button
            @click="
              () => {
                this.dialogVisible = true;
              }
            "
            class="blueBtn"
            plain
            size="mini"
            >批量导入</el-button
          >
        </div>
        <div v-show="activeName == '2'" class="newadd">
          <el-button type="primary" size="mini" @click="add()"
            >新增回复</el-button
          >
        </div>
      </div>
      <div class="gray"></div>
      <!-- 店铺名-->
      <div class="detailTest-detail">
        <div>
          <el-avatar
            :src="shopDetail.shopLogo"
            v-if="shopDetail.shopLogo"
          ></el-avatar>
          <span v-if="!shopDetail.shopLogo"
            ><i class="el-icon-user common-iconImg-MR"></i
          ></span>
        </div>
        <div class="detail-title">
          <span class="shopname"
            >店铺名称: {{ shopDetail.shopName || "--" }}</span
          >
          <span class="platname"
            >所属平台：{{ shopDetail.platformName || "--" }}</span
          >
        </div>
      </div>
    </div>
    <div class="line"></div>

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="自动回复" name="1">
        <!-- 搜索框 -->
        <div class="common-screen-container">
          <div class="common-input-container">
            <span>问题名称:</span>
            <el-input
              class="common-screen-input"
              v-model="params.questionName"
              placeholder="请填写问题名称"
            ></el-input>
          </div>
          <div class="common-input-container">
            <span>问题分类:</span>
            <el-select
              v-model="params.typeId"
              placeholder="请选择"
              class="common-screen-input"
              filterable
              @change="getAutoReplyList"
              clearable
            >
              <el-option
                v-for="item in classList"
                :key="item.id"
                :label="item.typeName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <el-button type="primary" size="small" @click="select"
            >查询</el-button
          >
          <el-button size="small" @click="reset">重置</el-button>
        </div>
        <!-- 列表区域 -->
        <el-table
          :data="tableData"
          style="width: 100%"
          class="common-table common-table-bgGrey"
        >
          <el-table-column prop="question_name" label="问题名称">
          </el-table-column>
          <el-table-column prop="typeName" label="问题分类"> </el-table-column>
          <!-- <el-table-column prop="key_word" label="关键词" width="200">
          </el-table-column> -->
          <el-table-column label="问题答案">
            <template slot-scope="scope">
              <span
                style="overflow:hidden;white-space:normal; word-break:break-all;text-overflow:ellipsis;"
                >{{ scope.row.word_content }}</span
              >

              <img
                class="qua-img"
                :src="scope.row.img_content"
                v-if="scope.row.img_content"
              />
            </template>
          </el-table-column>
          <el-table-column label="问答标签">
            <template slot-scope="scope">
              <span
                v-if="scope.row.create_type == '服务商'"
                class="common-tableStatus-blue-bgc"
                >{{ scope.row.create_type }}</span
              >
              <span
                v-if="scope.row.create_type == '品牌方'"
                class="common-tableStatus-orange-bgc"
                >{{ scope.row.create_type }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="create_from" label="问答来源">
          </el-table-column>
          <el-table-column prop="last_name" label="最近维护人" width="180">
          </el-table-column>
          <el-table-column prop="last_time" label="最近维护时间">
          </el-table-column>
          <el-table-column prop="create_name" label="创建人"> </el-table-column>
          <el-table-column label="是否使用">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.is_open"
                :active-value="1"
                :inactive-value="0"
                active-color="#1890FF"
                common-grey-line="#DBDBDB"
                @change="
                  (val) => {
                    changeShow(scope.row, val);
                  }
                "
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="addDrawer('edit', scope.row)"
                >编辑 </el-button
              >&nbsp;&nbsp;&nbsp;

              <el-popconfirm
                title="确定删除吗？"
                placement="top"
                @confirm="
                  () => {
                    deleteItem(scope.row.id);
                  }
                "
              >
                <el-button type="text" slot="reference"> 删除 </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          style="text-align: center"
          @size-change="(e) => pageFun(e, 'pageSize')"
          @current-change="(e) => pageFun(e)"
          :page-sizes="pagination.pageSizes"
          :page-size="params.pageSize"
          :current-page="params.pageNum"
          layout="total, prev, pager, next,sizes, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="首响回复" name="2">
        <auto-first
          :loading="loading2"
          :tableData="firstData"
          @getFirstTimeList="getFirstTimeList"
        ></auto-first>
        <!-- 分页 -->
        <!-- <el-pagination
          style="text-align: center"
          @size-change="(e) => pageFun2(e, 'pageSize')"
          @current-change="(e) => pageFun2(e)"
          :page-sizes="pagination2.pageSizes"
          :page-size="params2.pageSize"
          :current-page="params2.pageNum"
          layout="total, prev, pager, next,sizes, jumper"
          :total="pagination2.total"
        >
        </el-pagination> -->
      </el-tab-pane>
    </el-tabs>

    <!-- 批量导入dialog -->
    <el-dialog
      title="导入问答"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="importClose"
      :append-to-body="true"
    >
      <!-- 主体 -->
      <ul>
        <li class="one">
          下载模板
          <el-button
            @click="() => downloadTemplate()"
            type="primary"
            plain
            size="mini"
            icon="iconfont icon-antOutline-cloud-download"
            class="dbtn"
            >下载模板</el-button
          ><br/>
          <span class="greytext">请按数据模板的格式准备要导入的数据</span>
        </li>
        <li class="one">
          <div class="upload-icon-container">
            导入文件
            <el-button
              type="primary"
              size="mini"
              icon="iconfont icon-antOutline-cloud-upload"
              class="dbtn"
              >导入
            </el-button>
            <input
              class="common-upload-input"
              @input="
                (e) => {
                  btnImport(e);
                }
              "
              type="file"
            />
          </div>
          <div class="greytext">选择模板进行导入</div>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="uploadFile" size="mini"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 自动回复--新增&&编辑 -->
    <el-dialog
      v-dialogDrag
      :title="type == 'add' ? '新增' : '编辑'"
      :append-to-body="true"
      :visible.sync="addEditVisible"
      width="560px"
      :before-close="addEditHandleClose"
    >
      <el-form
        :model="autoForm"
        :rules="rules"
        ref="autoForm"
        label-width="100px"
        class="demo-autoForm"
      >
        <el-form-item label="问题名称" prop="questionName">
          <el-input v-model="autoForm.questionName"></el-input>
        </el-form-item>
        <el-form-item label="问题分类" prop="typeId">
          <el-select
            v-model="autoForm.typeId"
            placeholder="请选择活动区域"
            class="select"
          >
            <el-option
              v-for="item in classList"
              :key="item.id"
              :label="item.typeName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="回答设置" prop="wordContent">
          <el-input
            v-model="autoForm.wordContent"
            type="textarea"
            maxlength="500"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="addEditHandleClose()"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="requestBtn()"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 首响--新增 -->
    <el-dialog
      v-dialogDrag
      title="新增"
      :append-to-body="true"
      :visible.sync="addVisible"
      width="560px"
      :before-close="addHandleClose"
    >
      <div style="display: flex;">
        <div>首响内容：</div>
        <el-input
          type="textarea"
          :rows="5"
          placeholder="请输入内容"
          v-model="wordContent"
          maxlength="500"
          show-word-limit
          class="iptarea"
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="addVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="requestAdd()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { getShopDetail } from "../../service/common.js";
import { Config } from "../../utils/index.js";
import { uploadFile, listNew } from "../../service/auto";
import {
  autoReply,
  editAutoReply,
  delQuestion,
  // questionList,
  firstList,
  firstAdd,
  editQuestion,
  addQuestion
} from "../../service/auto";
import autoFirst from "./autoFirst.vue";

export default {
  name: "qaManage",
  components: {
    autoFirst
  },
  props: {},
  data() {
    return {
      //   分页
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      loading: false,
      shopDetail: {}, // 店铺详情
      params: {
        pageNum: 1,
        pageSize: 10,
        status: 2,
        shopId: JSON.parse(this.$route.query.shopid)
      },
      autoList: [
        {
          status: 0,
          label: "关闭"
        },
        {
          status: 1,
          label: "开启"
        },
        {
          status: 2,
          label: "全部"
        }
      ], //自动回复下拉数据
      classList: [], //所属分类下拉数据
      tableData: [], //列表区域
      drawer: false, //抽屉显隐
      dialogVisible: false, //批量导入dialog
      activeName: "1",
      loading2: false,
      params2: {
        pageNum: 1,
        pageSize: 10
      },
      //   分页
      pagination2: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      firstData: [],
      wordContent: "",
      addVisible: false,
      addEditVisible: false, //自动回复--新增-编辑 弹弹弹
      type: "",
      autoForm: {
        //自动回复--新增、编辑
        questionName: "", //问题名称
        // 问题分类
        typeId: "",
        // 回答设置
        wordContent: ""
      },
      rules: {
        //自动回复校验--新增、编辑
        questionName: [
          { required: true, message: "请输入问题名称", trigger: "change" }
        ],
        // 问题分类
        typeId: [
          { required: true, message: "请选择问题分类", trigger: "change" }
        ],
        // 回答设置
        wordContent: [
          { required: true, message: "请输入回答设置", trigger: "change" }
        ]
      },
      tableRow: {} //自动编辑
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 自动回复列表
    async getAutoReplyList() {
      this.loading = true;
      try {
        let params = { ...this.params };
        const { data: res } = await autoReply(params);
        this.tableData = res.records;
        this.pagination.total = res.total;
        this.params.pageSize = res.size;
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    // 分页
    pageFun(e, type) {
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.getAutoReplyList();
    },
    pageFun2(e, type) {
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.getFirstTimeList();
    },
    async getShopDetail() {
      // 获取店铺详情
      let resData = (await getShopDetail({ shopId: this.$route.query.shopid }))
        .data;
      this.shopDetail = resData[0];
    },
    // 新增 || 编辑
    addDrawer(type, row) {
      this.type = type;
      this.tableRow = row;
      if (row) {
        this.autoForm = {
          questionName: row.question_name,
          typeId: row.typeId,
          wordContent: row.word_content
        };
      }
      this.addEditVisible = true;
    },
    // 子组件关闭事件
    addEditHandleClose() {
      this.autoForm = {
        questionName: "",
        typeId: "",
        wordContent: ""
      };
      this.$refs["autoForm"].resetFields();
      this.addEditVisible = false;
    },
    // 删除
    async deleteItem(id) {
      console.log(id);
      const res = await delQuestion({}, id);
      if (res.code != 200) return;
      this.getAutoReplyList();
      this.$message.success("删除成功");
    },
    // 批量 模板下载
    downloadTemplate() {
      // 下载模板-暂时写死
      window.location.href =
        "https://sy-my.oss-cn-zhangjiakou.aliyuncs.com/sy/file/%E7%9F%A5%E8%AF%86%E5%BA%93%E9%97%AE%E9%A2%98%E5%88%86%E7%B1%BB.xlsx";
    },
    // 批量 导入按钮
    async btnImport(e) {
      this.loading = true;
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      // console.log(format,'format');//jpeg
      if (file.size / 1024 > 1024 * 50) {
        e.target.value = "";
        return this.$message.error("文件大小不能超过50M");
      }
      if (!Config.fileType.excelType.includes(format)) {
        e.target.value = "";
        return this.$message.error(
          `只能上传 ${Config.fileMessage.excelType} 格式的文件`
        );
      }
      // if (format != "xls" && format != "xlsx") {
      //   this.$message.error("仅支持xls、xlsx格式文件");
      //   e.target.value = "";
      //   this.loading = false;
      //   return;
      // }
      let { code } = await uploadFile({
        excelFile: file,
        shopId: this.params.shopId
      });
      e.target.value = "";
      if (code == 200) {
        this.$message.success("上传成功！");
      }
      this.dialogVisible = false;
      this.getAutoReplyList();
      this.loading = false;
    },
    // 批量导入dialog关闭
    importClose() {
      this.dialogVisible = false;
    },
    // 批量导入dialog 确定按钮
    uploadFile() {
      this.dialogVisible = false;
    },
    select() {
      this.getAutoReplyList();
    },
    reset() {
      this.params = {
        pageNum: 1,
        pageSize: 10,
        status: 2,
        shopId: JSON.parse(this.$route.query.shopid)
      };
      this.getAutoReplyList();
    },
    handleClick(tab) {
      console.log(tab.name);
      if (tab.name == "2") {
        this.getFirstTimeList();
      }
    },
    async getFirstTimeList() {
      let params = { ...this.params2 };
      params.shopId = this.$route.query.shopid;
      this.loading2 = true;
      const { data } = await firstList(params);
      this.loading2 = false;
      this.firstData = data;
      // this.pagination2.total = data.total
    },
    add() {
      if (this.firstData.length >= 5) {
        return this.$message({
          onClose: () => {},
          dangerouslyUseHTMLString: true,
          showClose: true,
          duration: 3000,
          iconClass: "el-icon-warning-outline",
          customClass: "common-tip-alert",
          message:
            '<div class="tip-title"> <i class="iconfont icon-guanyu"></i> 新增提醒</div><div class="tip-info">每个店铺首响回复只能创建五条。</div>'
        });
      }
      this.addVisible = true;
    },
    async requestAdd() {
      const res = await firstAdd({
        shopId: this.$route.query.shopid,
        wordContent: this.wordContent
      });
      if (res.code == 200) {
        this.$message.success("新增成功");
      }
      this.getFirstTimeList();
      this.addHandleClose();
    },
    addHandleClose() {
      this.wordContent = "";
      this.addVisible = false;
    },
    // 列表switch改变
    async changeShow(row, val) {
      console.log(row, val);
      this.tableRow = row;
      this.loading = true;
      const res = await editAutoReply(row.id);
      this.loading = false;
      if (res.code == 200) {
        this.$message.success("修改成功");
      }
      this.getAutoReplyList();
    },
    // 自动回复新增、编辑弹窗确定按钮
    requestBtn() {
      this.$refs["autoForm"].validate(async (valid) => {
        if (valid) {
          let params = { ...this.autoForm };
          if (this.type != "edit") {
            params.shopId = this.$route.query.shopid
            params.createFrom = "0"
            params.createType = "0"
            const res = await addQuestion(params);
            if (res.code == 200) {
              this.$message.success("新增成功");
            }
          } else {
            params.shopId = this.$route.query.shopid;
            const res = await editQuestion(params, this.tableRow.id);
            if (res.code == 200) {
              this.$message.success("修改成功");
            }
          }

          this.$refs["autoForm"].resetFields();
          this.addEditVisible = false;
          this.getAutoReplyList();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async getClassList() {
      const { data } = await listNew();
      this.classList = data;
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getShopDetail();
    // this.getSortList();
    this.getAutoReplyList();
    this.getClassList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
};
</script>

<style lang="less" scoped>
.qumanage-container {
  /deep/.newadd {
    span {
      font-size: 14px !important;
    }
  }
  .blueBtn {
    color: #1890ff;
    border-color: #1890ff;
  }
  .detailTest-detail {
    display: flex;
    align-items: center;
    padding: 12px 32px;
    align-items: center;
    color: #000000a6;
    .common-iconImg-MR {
      width: 56px;
      height: 56px;
      line-height: 56px;
      font-size: 40px;
    }
    .detail-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 14px;
    }
    .shopname {
      font-size: 20px;
      color: #333;
    }
    .platname {
      font-size: 14px;
      color: #999;
    }
  }
  .common-screen-container {
    margin: 0;
  }
  .newadd {
    display: flex;
    justify-content: space-between;
  }
  /deep/ .el-tabs__content {
    padding: 24px 32px;
  }
}
.one {
  margin-bottom: 14px;
}
.upload-icon-container {
  position: relative;
  margin-top: 14px;
}
.qua-img {
  width: 30px;
  height: 30px;
}
.hd {
  height: 153px;
  .name {
    display: flex;
    justify-content: space-between;
    color: rgba(16, 16, 16, 100);
    font-size: 24px;
    padding: 18px 32px;
    span {
      font-size: 24px;
    }
    .addBtn {
      border-color: #1890ff;
      color: #1890ff;
    }
  }
  .gray {
    height: 1px;
    background-color: rgba(230, 230, 230, 100);
  }
  .common-screen-container {
    padding-left: 16px;
  }
}
.line {
  height: 16px;
  background-color: #ededed;
}
/deep/ .select {
  width: 100%;
}
/deep/ .iptarea {
  width: 85%;
}

.dbtn {
  margin-left: 24px;
  width: 116px;
  height: 40px;
}
.greytext {
  display: inline-block;
  color: #D0D0D0;
  margin-top: 12px;
  margin-left: 80px;
}
</style>
<style lang="less">
.iconfont {
  margin-right: 8px !important;
}
</style>
