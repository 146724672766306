<!--  -->
<template>
  <div
    v-loading="loading"
    element-loading-text="数据较多，拼命加载中..."
    class=""
  >
    <!-- 列表区域 -->
    <el-table :data="tableData" style="width: 100%" class="common-table common-table-bgGrey">
      <el-table-column prop="wordContent" label="首响内容"> </el-table-column>
      <el-table-column prop="createName" label="创建人"> </el-table-column>
      <el-table-column prop="createTime" label="创建时间"> </el-table-column>
      <el-table-column prop="lastTime" label="更新时间"> </el-table-column>
      <el-table-column label="是否使用">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.isOpen"
            :active-value="1"
            :inactive-value="0"
            active-color="#1890FF"
            common-grey-line="#DBDBDB"
            @change="
              (val) => {
                changeShow(scope.row, val);
              }
            "
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="
              () => {
                edit(scope.row);
              }
            "
            >编辑 </el-button
          >&nbsp;&nbsp;&nbsp;
          
          <el-button type="text" @click="deleteItem(scope.row.id);" slot="reference"> 删除 </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      v-dialogDrag
      title="编辑"
      :append-to-body="true"
      :visible.sync="editVisible"
      width="560px"
      :before-close="handleClose"
    >
      <div style="display: flex;">
        <div>首响内容：</div>
        <el-input
          type="textarea"
          :rows="5"
          placeholder="请输入内容"
          v-model="wordContent"
          maxlength="500"
          show-word-limit
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="editVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="updateEdit()"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 删除弹窗 -->
    <el-dialog v-dialogDrag :append-to-body="true" :visible.sync="delVisible" width="520px">
      <template slot="title"><div style="display:flex;align-items: center;"><img src="~@/assets/images/if-exclamation@1x.png" class="common-diaimgs"><span class="common-diatit">删除</span> </div></template>
      <div class="common-diacon">确定删除此条首响内容吗？删除后将无法恢复</div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="delVisible = false">取 消</el-button>
        <el-button class="common-screen-btn" type="primary" @click="() => {requestDelete()}">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { update } from "../../service/auto.js";

export default {
  name: "",
  components: {},
  props: ["loading", "tableData"],
  data() {
    return {
      editVisible: false,
      editId: "",
      wordContent: "",
      delId: "",
      delVisible: false,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //方法集合
  methods: {
    async changeShow(row, val) {
      const res = await update({ id: row.id, isOpen: val });
      if (res.code == 200) {
        this.$message.success("操作成功");
      }
      this.$emit("getFirstTimeList");
    },
    edit(row) {
      this.editId = row.id;
      this.wordContent = row.wordContent
      this.editVisible = true;
    },
    async updateEdit(){
      const res = await update({ id: this.editId, wordContent: this.wordContent });
      if (res.code == 200) {
        this.$message.success("操作成功");
      }
      this.handleClose()
      this.$emit("getFirstTimeList");
    },
    handleClose() {
      this.wordContent = ""
      this.editVisible = false;
    },
    deleteItem(id){
      this.delId = id
      this.delVisible = true
    },
    async requestDelete(){
      const res = await update({ id: this.delId, isDelete: 1 });
      if (res.code == 200) {
        this.$message.success("删除成功");
      }
      this.delVisible = false
      this.$emit("getFirstTimeList");
    }
  }
};
</script>

<style lang="less" scoped>
/deep/.el-textarea {
  width: 85% !important;
}
</style>
